import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    initialize() {

    }

    connect() {

    }

    copy_to_field(e){
        $(`#${e.target.dataset.copy}`)[0].value = e.target.value;
    }
}