import {Controller} from "stimulus"
import $ from 'jquery';
import Inputmask from "inputmask";

export default class extends Controller {
    connect() {
        let type = 'hours';
        let mask_input = this.element

        let im = '';
        if(mask_input.dataset.type !== undefined){
            type = mask_input.dataset.type
        }
        switch (type) {
            case 'text':
                im = new Inputmask({ regex: '[A-Z a-z]+'})
                im.mask(mask_input)
                break;
            case 'phone':
                im = new Inputmask({ mask: "+9{7,12}" })
                im.mask(mask_input)
                break;
            case 'alphanumeric':
                im = new Inputmask({ regex: "[A-Z a-z 0-9]+" })
                im.mask(mask_input)
                break;
            case 'email':
                im = new Inputmask({ regex: '^[-\\w.%+]{1,64}@([A-Za-z]{1,63}\\.)[A-Za-z]{2,3}$' })
                im.mask(mask_input)
                break;
            default:
                im = new Inputmask("99:99")
                im.mask(mask_input)
                break

        }

    }
}