import {Controller} from "stimulus"
import $ from "jquery";

export default class extends Controller {
    static targets = ["chart"]

    updateChart() {
        const chart = Chartkick.charts['chart_voucher']

        let range = $(`#${this.data.get('range')}`).val();

        if (range !== '') {
            if (range.split('a').map(fecha => fecha.trim()).length === 1) {
                return null;
            }
        }

        if (chart) {
            $.ajax({
                url: `${location.origin}${this.data.get("updateUrl")}`,
                data: {
                    format: 'json',
                    range: $(`#${this.data.get('range')}`).val(),
                    agency: $(`#${this.data.get('agency')}`).val(),
                    product: $(`#${this.data.get('product')}`).val(),
                    dealer: $(`#${this.data.get('dealer')}`).val(),
                    office_pickup: $(`#${this.data.get('office_pickup')}`).val(),
                    office_dropoff: $(`#${this.data.get('office_dropoff')}`).val()
                },
                type: 'get',
                success: function (data) {
                    chart.updateData(data.data);
                }
            })
        }
    }
}
