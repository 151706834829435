import {Controller} from "stimulus"
import $ from 'jquery';
import {check_availability} from "./formAction_controller";
import bootbox from "bootbox";

let request_send = (params) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/request-reservation`,
        data: params,
        type: 'POST',
        success: function (data) {
            resolve(data);
        }
    })
})
let get_name_for_data_send = (params) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/get_name_for_data_send`,
        data: params,
        type: 'GET',
        success: function (data) {
            resolve(data);
        }
    })
})
let send = (params) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/reservation`,
        data: params,
        type: 'POST',
        success: function (data) {
            resolve(data);
        }
    })
})

export default class extends Controller {

    initialize() {

        this.validate = false;
        this.show_agency_fields = this.element.dataset.showFields;
        this.next_click = document.querySelectorAll(".next_button");
        this.main_form = document.querySelectorAll(".main");
        this.step_list = document.querySelectorAll(".progress-bar li");
        this.num = document.querySelector(".step-number");
        this.formnumber = 0;
        this.back_click = document.querySelectorAll(".back_button");
        this.username = document.querySelector("#user_name");
        this.submit_click = document.querySelectorAll(".submit_button");
        this.request_submit_click = document.querySelectorAll(".request_submit_button");
        this.submit_back = document.querySelectorAll(".submit_back");
        this.step_num_content = document.querySelectorAll(".step-number-content");
    }

    connect() {
        let context = this;
        this.next_click.forEach(function (next_click_form) {
            next_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }
                context.formnumber++;
                context.updateform();
                context.progress_forward();
                context.contentchange();
            });
        });
        this.back_click.forEach(function (back_click_form) {
            back_click_form.addEventListener('click', function () {
                context.formnumber--;
                context.updateform();
                context.progress_backward();
                context.contentchange();
            });
        });
        this.submit_click.forEach(function (submit_click_form) {
            submit_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }

                if (submit_click_form.dataset.hasOwnProperty('form')) {
                    submit_click_form.innerHTML = "<span class='fa fa-spinner fa-spin fa-2x' style='color: white;'></span>";
                    submit_click_form.setAttribute('disabled', "disabled");
                    let form = $(`#${submit_click_form.dataset.form}`);
                    let input = form.find('input[type="text"]');
                    let input_hidden = form.find('input[type="hidden"]');
                    let checkbox = form.find('input[type="checkbox"]');
                    let select = form.find('select');
                    let data = {};

                    if (input.length > 0) {
                        input.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if (input_hidden.length > 0) {
                        input_hidden.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if (checkbox.length > 0) {
                        checkbox.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.checked
                            }
                        })
                    }
                    if (select.length > 0) {
                        select.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }

                    get_name_for_data_send(data).then(response => {
                        let template = `<span class="text-danger" style="font-size: 1.5em; font-weight: bold; text-align: justify;">¡Atención usted está a 1 paso de confirmar la reserva!</span>
                                                <hr>
                                                La reserva a confirmar tiene los siguientes datos:
                                                <br>
                                                <br>
                                                <strong>Destino: </strong> ${response.destination}<br>
                                                <strong>Desde: </strong> ${response.from}<br>
                                                <strong>Hasta: </strong> ${response.to}<br>
                                                <strong>Reservando un:</strong> ${response.service}<br>
                                                <strong>A recoger en:</strong> ${response.pickup_office}<br>
                                                <strong>Y entregar en:</strong> ${response.drop_office}<br>
                                                <br>
                                                Esta reserva se confirmará a nombre del cliente<br>
                                                <strong>${response.client_name}</strong><br>
                                                Con número de pasaporte<br>
                                                <strong>${response.client_passport}</strong>
                                                <hr>
                                                <span class="text-danger">Confirme que los datos anteriormente expuesto son los datos de la reserva, de no estar seguro puede regresar el proceso para corregirlo. </span>
                                                `
                        bootbox.confirm({
                            message: template,
                            buttons: {
                                confirm: {
                                    label: 'Confirmar reserva',
                                    className: 'btn-success'
                                },
                                cancel: {
                                    label: 'Regresar',
                                    className: 'btn-secondary'
                                },
                            },
                            callback: function (result) {
                                if (result) {
                                    let client_text = $('#client_text');
                                    if(client_text.length > 0){
                                        client_text.removeClass('ocultar');
                                    }
                                    send(data).then(r => {

                                        if(client_text.length > 0){
                                            client_text.addClass('ocultar');
                                        }

                                        let check = $(`#check_${r.success}`);
                                        let not_check = $(`#check_${!r.success}`);
                                        let check_title = $(`#check_title`);
                                        let check_text = $(`#check_text`);
                                        let timing = $('#timing');
                                        if(timing.length > 0){
                                            timing[0].innerHTML = '';
                                        }
                                        not_check.addClass('d-none');
                                        check.removeClass('d-none');
                                        check_title[0].innerHTML = r.code;
                                        check_text[0].innerHTML = r.message

                                        context.formnumber++;
                                        context.updateform();

                                        if (!r.success) {
                                            check_title[0].parentNode.parentNode.classList.add('error');
                                            check_title[0].classList.add('error-text');
                                            check_text[0].classList.add('error-text');
                                            context.submit_back.forEach(function (submit_back_form) {
                                                submit_back_form.classList.remove('ocultar');
                                            });
                                        } else {
                                            check_title[0].parentNode.parentNode.classList.remove('error');
                                            check_title[0].classList.remove('error-text');
                                            check_text[0].classList.remove('error-text');
                                            submit_click_form.innerHTML = "Reservar";
                                            submit_click_form.removeAttribute('disabled');
                                            context.submit_back.forEach(function (submit_back_form) {
                                                submit_back_form.classList.add('ocultar');
                                            });
                                        }
                                    });
                                }else{
                                    submit_click_form.innerHTML = "Reservar";
                                    submit_click_form.removeAttribute('disabled');
                                }
                            }
                        });
                    })
                } else {
                    context.formnumber++;
                    context.updateform();
                }

            });
        });
        this.request_submit_click.forEach(function (request_submit_click_form) {
            request_submit_click_form.addEventListener('click', function () {
                if (!context.validateform()) {
                    return false
                }

                if (request_submit_click_form.dataset.hasOwnProperty('form')) {
                    let form = $(`#${request_submit_click_form.dataset.form}`);
                    let input = form.find('input[type="text"]');
                    let input_hidden = form.find('input[type="hidden"]');
                    let checkbox = form.find('input[type="checkbox"]');
                    let select = form.find('select');
                    let data = {};

                    if (input.length > 0) {
                        input.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if (input_hidden.length > 0) {
                        input_hidden.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }
                    if (checkbox.length > 0) {
                        checkbox.map((index, elem) => {
                            if (elem.name.length > 0) {

                                data[elem.name] = elem.checked
                            }
                        })
                    }
                    if (select.length > 0) {
                        select.map((index, elem) => {
                            if (elem.name.length > 0) {
                                data[elem.name] = elem.value
                            }
                        })
                    }

                    request_send(data).then(r => {
                        let check = $(`#check_${r.success}`);
                        let not_check = $(`#check_${!r.success}`);
                        let check_title = $(`#check_title`);
                        let check_text = $(`#check_text`);

                        not_check.addClass('d-none');
                        check.removeClass('d-none');
                        check_title[0].innerHTML = r.code;
                        check_text[0].innerHTML = r.message

                        context.formnumber++;
                        context.updateform();

                        if (!r.success) {
                            check_title[0].classList.add('error-text');
                            check_text[0].classList.add('error-text');
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.remove('ocultar');
                            });
                        } else {
                            check_title[0].classList.remove('error-text');
                            check_text[0].classList.remove('error-text');
                            context.submit_back.forEach(function (submit_back_form) {
                                submit_back_form.classList.add('ocultar');
                            });
                        }
                    });

                } else {
                    context.formnumber++;
                    context.updateform();
                }

            });
        });
        this.submit_back.forEach(function (submit_back_form) {
            submit_back_form.addEventListener('click', function () {
                context.formnumber--;
                context.updateform();
            });
        });

    }

    updateform() {
        this.main_form.forEach(function (mainform_number) {
            mainform_number.classList.remove('active');
        })
        this.main_form[this.formnumber].classList.add('active');
    }

    progress_forward() {
        this.num.innerHTML = this.formnumber + 1;
        this.step_list[this.formnumber].classList.add('active');
    }

    progress_backward() {
        let form_num = this.formnumber + 1;
        this.step_list[form_num].classList.remove('active');
        this.num.innerHTML = form_num;
    }

    contentchange() {
        this.step_num_content.forEach(function (content) {
            content.classList.remove('active');
            content.classList.add('d-none');
        });
        this.step_num_content[this.formnumber].classList.add('active');
        this.step_num_content[this.formnumber].classList.remove('d-none');
    }

    validateform() {
        let validate = true;
        let validate_inputs = document.querySelectorAll(".main.active input");
        let validate_select = document.querySelectorAll(".main.active select");
        if (validate_inputs.length > 0) {
            validate_inputs.forEach(function (vaildate_input) {
                vaildate_input.classList.remove('warning');
                if (vaildate_input.classList.contains('required')) {
                    if (vaildate_input.value.length === 0) {
                        validate = false;
                        vaildate_input.classList.add('warning');
                    }
                }
            });
        }

        if (validate_select.length > 0) {
            validate_select.forEach(function (validate_select) {
                validate_select.classList.remove('warning');
                if (validate_select.classList.contains('required')) {
                    if (validate_select.value.length === 0) {
                        validate = false;
                        validate_select.classList.add('warning');
                    }
                }
            });
        }
        return validate;

    }

    checkAvailability(e) {
        let context = this;
        if (this.validateform()) {
            let field = e.target.dataset;
            let params = {};
            if (field.hasOwnProperty('check')) {
                let check = $(`#${field.check}`).find('.availability');
                let authenticity_token = $(`input[name="authenticity_token"]`).val();
                if (check.length > 0) {
                    check.map((index, elem) => {
                        if (elem.name !== undefined && elem.name.length > 0) {
                            params[elem.name] = elem.value;
                        }
                    })
                    params["authenticity_token"] = authenticity_token;
                    check_availability(params, e.target).then(r => {
                        $(e.target).removeAttr('disabled');
                        e.target.innerHTML = 'Siguiente';

                        if (r.success) {
                            if (r.hasOwnProperty('timestamp')) {
                                let date = new Date();
                                sessionStorage.setItem('reservation_time', `${date.setMinutes(date.getMinutes() + 8)}`)
                                sessionStorage.setItem('has_reservation', 'true')
                                setTimeout(() => {
                                    $('#timing').html('<div class="time_remaining" data-controller="alert"></div>')
                                }, 500);
                            } else {
                                sessionStorage.removeItem('has_reservation')
                                sessionStorage.removeItem('reservation_time')
                            }
                            toastr.success(r.message);
                            context.formnumber++;
                            context.updateform();
                            context.progress_forward();
                            context.contentchange();
                        } else {
                            toastr.warning(r.message);
                        }
                    });
                }
            } else {
                toastr.warning('Faltan parámetros para validar');
            }
        }
    }

    showAlert() {
        let context = this;
        let template = `<div class="row">
<div class="col-12 alert-text" style="overflow: hidden">
  . Atención . Atención . Atención . Atención . Atención . Atención . Atención . Atención . Atención . Atención . Atención . Atención . 
</div>
<div class="col-md-12 d-flex justify-content-center align-items-center">
<span class="fa fa-exclamation-triangle alert-icon"></span>
</div>
<div class="col-md-12 text-justify"> LA RESERVA QUE USTED SOLICITA TIENE FECHA DE INICIO EN MENOS DE 48 HORAS. SU CONFIRMACIÓN NO SERÁ INMEDIATA.<br>ROGAMOS NOS ENVÍE LOS DATOS Y EN BREVE LO CONTACTAREMOS PARA INFORMARLE SI ES POSIBLE O NO REALIZAR LA RESERVA. <br><br><span class="text-danger">ESTE PREOCESO ES SOLO UNA SOLICITUD, NO UNA RESERVA CONFIRMADA POR NUESTRA PARTE.</span></div>
</div>`;
        bootbox.confirm({
            message: template,
            buttons: {
                confirm: {
                    label: 'ENTENDIDO',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'No',
                    className: 'btn-secondary'
                },
            },
            callback: function (result) {
                if (result) {
                    context.formnumber++;
                    context.updateform();
                    context.progress_forward();
                    context.contentchange();
                }
            }
        });
    }

}

