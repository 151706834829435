import {Controller} from "stimulus"
import $ from 'jquery';
import {validate_email} from "./validate_controller";


export default class extends Controller {
    connect() {

    }

    validate_form(e){
        let button = e.target.id
        let target = $('#'+e.target.dataset.validate);
        let required_field_input = target.find('input.required');
        let required_field_select = target.find('select.required');
        let required_field_checkbox = target.find('checkbox.required');

        let error = 0;
        required_field_input.each((index, elem) => {
            let field = $(elem)[0];
            let helper = $(field.parentNode).find('.helper-text');
            if (field.value.isEmpty()) {
                // helper.html('<p class="text text-danger">Este campo no puede estar vacío</p>');
                field.classList.add('is-invalid');
                error++;
            } else {
                let field_name = field.name

                if(field.name.includes('email')){
                    validate_email(field.value, field.id, null).then(r => {
                        if(!r){
                            error++;
                        }
                    })

                }
                else{
                    field.classList.remove('is-invalid');
                    helper.html('');
                }
            }
        });
        required_field_select.each((index, elem) => {
            let field = $(elem)[0];
            let helper = $(field.parentNode).find('.helper-text');
            if (field.value.isEmpty()) {
                if(field.classList.contains('select2-hidden-accessible')){
                    let select_2_field = $(field)[0].parentNode;
                    let parent_node = $(select_2_field).find('.select2-selection');
                    parent_node[0].classList.add('is-invalid');
                }
                // helper.html('<p class="text text-danger">Este campo no puede estar vacío</p>');
                $(field.parentNode).addClass("is-invalid");
                error++;
            } else {
                if(field.classList.hasOwnProperty('select2-hidden-accessible')){
                    if(field.classList.contains('select2-hidden-accessible')){
                        let select_2_field = $(field)[0].parentNode;
                        let parent_node = $(select_2_field).find('.select2-selection');
                        parent_node[0].classList.remove('is-invalid');
                    }
                }
                // helper.html('');
                $(field.parentNode).removeClass("is-invalid");
                // helper.html('');
            }
        });
        required_field_checkbox.each((index, elem) => {
            let checkbox = $(elem)[0];
            let helper = $(checkbox).find('.helper-text');
            if (checkbox.value.isEmpty()) {
                // helper.html('<p class="text text-danger">Este campo no puede estar vacío</p>');
                $(checkbox).addClass("is-invalid");

                error++;
            } else {
                helper.html('');
                $(checkbox).removeClass("is-invalid");
                // helper.html('');
            }
        });

        if(error === 0){
           target.submit();
        }
    }


}